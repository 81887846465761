@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --primary-clr : #F4F4F4;
  --secondary-clr : #007CBD;
  --ternary-clr: #D9D9D9;
  --quad-clr: #00A67E;

  --link-clr:#899877;
  --btn-clr: #1D4443;

  --font-color-primary: #000;
  --font-color-secondary: #fff;
  

  --more-primary-clr: #6455D9;
  --more-secondary-clr: #94F6FF;

  --selected-clr: #00a67e;

  --padding: 10rem;

  --heroImages-duration: 30s;
  --heroImages-duration-half: 15s;

  /* --primary-font: 'Climate Crisis', cursive; */
  --primary-font: 'Poppins', sans-serif;
  /* --primary-font: 'Raleway', sans-serif; */
  --secondary-font: 'Barlow Condensed', sans-serif;


}

@media screen and (max-width: 768px) {
  :root{
      --padding: 2rem;
  }
  
}

@media screen and (min-width: 1920px) {
  :root{
      --padding: 20%;
  }
  
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-clr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
