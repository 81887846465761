.error404, .error{
	height: 100vh;
	color: var(--font-color-primary);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	font-family: var(--primary-font);
}

.error a{
	border: 1px solid black;
	padding: 1em 3em;
}

.loading{
	height: 10vmin;
}

.cls {
	fill: none;
	stroke: #00a67e;
	stroke-miterlimit: 10;
	stroke-width: 50px;
}

.cls2{
	--length: 2000;
	--double-length: 4000;
	stroke-dasharray: var(--length);
	stroke-dashoffset: var(--length);
	animation: loadinganimation 5s cubic-bezier(0.45, 0, 0.55, 1) infinite;
}

@keyframes loadinganimation {
	0%{
		stroke-dashoffset: var(--length);
	}
	50.99999%{
		stroke-dashoffset: 0;
	}
	51%{
		stroke-dashoffset: var(--double-length);
	}
	100%{
		stroke-dashoffset: var(--length);
	}
}