.blogPageBody{
    position: relative;
	background-color: var(--primary-clr);
	padding: 0 var(--padding);
	display: grid;
	grid-template-columns: repeat(1fr, 10);
}

.blogPageImage{
    grid-column: 1 / -1;
    height: 30vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.blogPageContent{
    --color-canvas-default: var(--primary-clr);
    margin: auto !important;
    width: min(50rem, 100%);
    background-color: var(--primary-clr)!important;
}

.blogPageContent h1:first-child{
    font-size: 3rem;
    font-weight: 600;
    font-family: var(--secondary-font);
    margin: 2rem 0;
}

.blogPageUserData{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userImage{
    height: 3em;
    width: 3em;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1rem;
}

.blogPageContent h1{
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--secondary-font);
    margin: 2rem 0;
}

.blogPageContent h2{
    font-size: 1.5rem;
    font-weight: 700 !important;
    font-family: var(--secondary-font);
    margin-top: 2.5em ;
    margin-bottom: 0.5em;
    border: none !important;
}

.blogPageContent h3{
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--secondary-font);
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    border: none !important;
}

.blogPageContent p{
    font-weight: 400;
    font-family: var(--primary-font);
    margin-top: 0.5em;
    margin-bottom: 1em;
    line-height: 1.75em;
}

.blogPageFooter{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.blogPageFooter > *{
    text-align: center;
    padding: 0.2rem 0;
    border-radius: 6px;
}

.loadingBlog{
    position: relative;
    grid-column: 1/-1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 30vh;
    font-weight: 600;
    font-family: var(--secondary-font);
    font-size: 2rem;
}

.loading{
	height: 10vmin;
	width: 10vmin;
}

.cls {
	fill: none;
	stroke: #00a67e;
	stroke-miterlimit: 10;
	stroke-width: 50px;
}

.cls2{
	--length: 2000;
	--double-length: 4000;
	stroke-dasharray: var(--length);
	stroke-dashoffset: var(--length);
	animation: loadinganimation 5s cubic-bezier(0.45, 0, 0.55, 1) infinite;
}

@keyframes loadinganimation {
	0%{
		stroke-dashoffset: var(--length);
	}
	50.99999%{
		stroke-dashoffset: 0;
	}
	51%{
		stroke-dashoffset: var(--double-length);
	}
	100%{
		stroke-dashoffset: var(--length);
	}
}