/*=======================================================
	
	Item	: 	N5 - Minimal Resume/CV & vCard Template
	
	Author	: 	Noman Ali Samejo
	
--------------------------------------------------------------------------------------
	List Of Contents
--------------------------------------------------------------------------------------
	
	01. General Styles
	
	02. Menu
	
	03. Section Styles
	
	04. Main Section
	
	05. About Section
	
	06. Resume Section
	
	07. Projects Section
	
	08. Contact Section
	
	09. Responsive CSS

=========================================================*/

/*=======================================================
	General Styles
=======================================================*/
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700,300,100|Lora:400,400italic,700,700italic);
#theme2{	
	height:100%;
	width:100%;
	// position:relative;
	position: absolute;
	// top:57px;
	// overflow:hidden;
	font-family: Lora, serif;
	// background:#F0F0F0 !important;
}
#theme2{
*{
	font-family: inherit;
}
.view-project,
.portfolio-filters > li > a,
.p-table > .header > .price,
.info-list > li > strong,
.btn-custom,
.menu-items > li > a,
h1, h2, h3, h4, h5, h6{
	font-family: 'Roboto Mono', sans-serif;
}
.color{
	color:#303F9F;
}
::-webkit-scrollbar{
	background:#F0F0F0;
	height:10px;
	width:10px;
}
::-webkit-scrollbar-thumb{
	background:#D0D0D0;
	border:solid 2px #F0F0F0;
}
::-webkit-scrollbar-thumb:hover{
	background:#A0A0A0;
}
img{
	max-width:100%;
}

#customId.border-dark .menu-btn > .lines > span{
	background:#303F9F ;
}
#customId.border-dark .main-content > .page-border{
	border-color:#303F9F ;
}
#customId.section-switching .main-content:before,
#customId.section-switching .main-content:after{
	z-index:299 ;
}
#customId.border-dark .main-content:before,
#customId.border-dark .main-content:after{
	border-color:#303F9F ;
}
#customId.section-switching .animation-block{
	position:absolute;
	left:0;
	height:0%;
	width:100%;
	background:#E0E0E0;
	z-index:199;
}
#customId.section-switching.down .animation-block{
	-webkit-animation: anim-down 2.5s cubic-bezier(.785,.135,.15,.86);
	-moz-animation: anim-down 2.5s cubic-bezier(.785,.135,.15,.86);
	animation: anim-down 2.5s cubic-bezier(.785,.135,.15,.86);
}
#customId.section-switching.up .animation-block{
	-webkit-animation: anim-up 2.5s cubic-bezier(.785,.135,.15,.86);
	-moz-animation: anim-up 2.5s cubic-bezier(.785,.135,.15,.86);
	animation: anim-up 2.5s cubic-bezier(.785,.135,.15,.86);
}
#customId.loaded #preloader{
	visibility:hidden;
}
#customId.loaded #preloader > .left{
	left:-50%;
}
#customId.loaded #preloader > .right{
	right:-50%;
}
#customId.loaded #preloader > .left,
#customId.loaded #preloader > .right,
#customId.loaded #preloader .loader{
	opacity:0;
	visibility:hidden;
}

/*=======================================================
	Menu
=======================================================*/

.menu-btn > .lines > .l2{
	margin:6px 0;
}
.menu{
	position:absolute;
	z-index:399;
	background:rgba(0,0,0,.9);
	top:0;
	right:0;
	height:100%;
	width:0;
	-webkit-transition:width .6s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:width .6s cubic-bezier(.785,.135,.15,.86);
	transition:width .6s cubic-bezier(.785,.135,.15,.86);
	overflow:hidden;
}
.menu:hover,
.menu-btn:hover + .menu{
	width:300px;
}
.menu > .inner{
	height:100%;
	width:300px;
	position:absolute;
	right:0;
	top:0;
}
.menu-items{
	margin:0;
	padding:0;
	list-style:none;
	position:absolute;
	width:100%;
	top:50%;
	left:0;
	-webkit-transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	-o-transform:translateY(-50%);
	transform:translateY(-50%);
	text-align:center;
}
.menu-items > li > a{
	color:#FFF;
	color:rgba(255,255,255,.6);
	font-size:18px;
	font-weight:700;
	text-decoration:none;
	text-transform:uppercase;
	letter-spacing:4px;
	padding:10px 0;
	margin:2px 0;
	display:block;
	-webkit-transition:color .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:color .3s cubic-bezier(.785,.135,.15,.86);
	transition:color .3s cubic-bezier(.785,.135,.15,.86);
}
.menu-items > li > a:hover{
	color:#FFF;
}
.main-content > .page-border{
	position:absolute;
	border:solid 1px #FFF;
	border-color:rgba(255,255,255,.4);
	z-index:999;
	-webkit-transition:border-color 2.5s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:border-color 2.5s cubic-bezier(.785,.135,.15,.86);
	transition:border-color 2.5s cubic-bezier(.785,.135,.15,.86);
}

.main-content > .border-top,
.main-content > .border-bottom{
	height:7px;
	left:20px;
	right:20px;
}
.main-content > .border-top{
	top:14px;
}
.main-content > .border-bottom{
	bottom:14px;
}
.main-content > .border-left,
.main-content > .border-right{
	width:7px;
	top:20px;
	bottom:20px;
}
.main-content > .border-right{
	right:14px;
}
.main-content > .border-left{
	left:14px;
}


/*=======================================================
	Section Styles
=======================================================*/
.main-content{
	position:absolute;
	// top:74px;
	// left:40px;
	// right:40px;
	// bottom:40px;
	z-index:99;
	height: 100%;
	width: 100%;
	overflow:hidden;
	box-shadow: 0 0 0 10px #FFF;
}
.section{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	overflow:auto;
	z-index:-1;
	visibility:hidden;
	padding-top:110px;
	background:#F0F0F0;
}
.section.active{
	visibility:visible;
	z-index:99;
}
.section-header{
	position:relative;
}
.section-header > h2{
	font-size:28px;
	font-weight:200;
	margin:0;
	text-transform:uppercase;
	letter-spacing:4px;
}
.section-block{
	padding:110px;
	padding-top:0;
}

.sections{
	height:100%;
	width:100%;
}


/*=======================================================
	Main Section
=======================================================*/
.section-main{
	background-size:cover;
	background-image:url(../img/bg.jpg);
}
.section-main:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	background:#303F9F;
	opacity:.8;
	z-index:-1;
}
.intro-text{
	padding:20px;
	text-align:center;
}
.intro-text > h1{
	margin:0;
	color:#FFF;
	text-transform:uppercase;
	font-weight:900;
	letter-spacing:8px;
	font-size:78px;
}
.intro-text > p{
	font-size:18px;
	color:#FFF;
	color:rgba(255,255,255,.8);
	margin-top:15px;
	margin-bottom:0;
	font-weight:300;
	font-style:italic;
	letter-spacing:2px;
}
.intro-text > .intro-btns{
	margin-top:45px;
}
.intro-text > .intro-btns > .btn-custom{
	margin:0 15px;
}
a.btn-custom{
	color:#FFF;
	text-decoration:none;
}
.btn-custom{
	color:#FFF;
	letter-spacing:4px;
	text-transform:uppercase;
	text-decoration:none;
	text-align:center;
	font-size:11px;
	font-weight:400;
	display:inline-block;
	*display:inline;
	*zoom:1;
	line-height:1em;
	padding:17px 32px;
	position:relative;
	z-index:5;
	background:transparent;
	border:none;
}
.btn-custom:after{
	content: ' ';
	position:absolute;
	top:0;
	left:-10px;
	right:-10px;
	bottom:0;
	-webkit-transform:rotate(-4deg);
	-moz-transform:rotate(-4deg);
	-ms-transform:rotate(-4deg);
	-o-transform:rotate(-4deg);
	transform:rotate(-4deg);
	z-index:-1;
	opacity:0;
	visibility:hidden;
	background:rgba(0,0,0,.3);
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.btn-custom:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:2;
	border:solid 1px #FFF;
	border-color:rgba(255,255,255,.8);
}
.btn-custom:hover:after{
	opacity:1;
	visibility:visible;
}
.btn-custom.btn-color:after{
	background:#303F9F;
}
.btn-custom.btn-color:hover:after{
	opacity:.4;
}
.btn-custom.btn-color{
	color:#202020;
}
.btn-custom.btn-color > span:before,
.btn-custom.btn-color > span:after{
	background:rgba(0,0,0,.2);
}
.btn-custom.btn-color:before{
	border-color:rgba(0,0,0,.2);
}

@-webkit-keyframes anim-down{
	0%{
		bottom:0;
		height:0;
	}
	45%{
		bottom:0;
		height:100%;
	}
	55%{
		bottom:0;
		height:100%;
	}
	100%{
		height:0%;
		top:0;
	}
}
@-moz-keyframes anim-down{
	0%{
		bottom:0;
		height:0;
	}
	45%{
		bottom:0;
		height:100%;
	}
	55%{
		bottom:0;
		height:100%;
	}
	100%{
		height:0%;
		top:0;
	}
}
@keyframes anim-down{
	0%{
		bottom:0;
		height:0;
	}
	45%{
		bottom:0;
		height:100%;
	}
	55%{
		bottom:0;
		height:100%;
	}
	100%{
		height:0%;
		top:0;
	}
}
@-webkit-keyframes anim-up{
	0%{
		top:0;
		height:0;
	}
	45%{
		top:0;
		height:100%;
	}
	55%{
		top:0;
		height:100%;
	}
	55.1%{
		bottom:0;
		top:auto;
		height:100%;
	}
	100%{
		height:0%;
		bottom:0;
		top:auto;
	}
}
@-moz-keyframes anim-up{
	0%{
		top:0;
		height:0;
	}
	45%{
		top:0;
		height:100%;
	}
	55%{
		top:0;
		height:100%;
	}
	55.1%{
		bottom:0;
		top:auto;
		height:100%;
	}
	100%{
		height:0%;
		bottom:0;
		top:auto;
	}
}
@keyframes anim-up{
	0%{
		top:0;
		height:0;
	}
	45%{
		top:0;
		height:100%;
	}
	55%{
		top:0;
		height:100%;
	}
	55.1%{
		bottom:0;
		top:auto;
		height:100%;
	}
	100%{
		height:0%;
		bottom:0;
		top:auto;
	}
}
.section-main{
	padding:0;
}
.section-main, .section-main > .container-fluid{
	height:100%;
	width:100%;
}
.v-align{
	display:table;
	height:100%;
	width:100%;
}
.v-align > .inner{
	height:100%;
	width:100%;
	display:table-cell;
	vertical-align:middle;
}



/*=======================================================
	About Section
=======================================================*/
.about-section{
	background:#F0F0F0;
}
.about-block .section-header{
	margin-bottom:80px;
}
.about-text > p{
	font-size:14px;
	color:rgba(0,0,0,.4);
	line-height:1.8em;
}
.about-text{
	margin-bottom:25px;
}
.about-btns > .btn-custom{
	margin-right:25px;
}
.info-list{
	padding:0;
	margin:0;
	list-style:none;
}
.info-list > li{
	padding:14px 0;
	border-bottom:solid 1px rgba(0,0,0,.1);
}
.info-list > li:last-of-type{
	border:none;
}
.info-list > li > strong{
	letter-spacing:4px;
	color:#202020;
	text-transform:uppercase;
}
.info-list > li > span{
	color:rgba(0,0,0,.4);
}
.services-block .section-header{
	margin-bottom:80px;
}
.service{
	padding:35px;
	position:relative;
}
.service:after{
	content: ' ';
	position:absolute;
	top:0;
	bottom:0;
	left:-10px;
	right:-10px;
	-webkit-transform:rotate(-4deg);
	-moz-transform:rotate(-4deg);
	-ms-transform:rotate(-4deg);
	-o-transform:rotate(-4deg);
	transform:rotate(-4deg);
	background:#303F9F;
	opacity:0;
	visibility:hidden;
	z-index:-1;
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.service:hover:after{
	visibility:visible;
	opacity:.4;
}
.service:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border:solid 1px rgba(0,0,0,.1);
	z-index:2;
}
.service > .icon{
	font-size:0;
	position:relative;
	z-index:5;
}
.service > .icon > i:before{
	font-size:54px;
	line-height:54px;
	vertical-align:middle;
	color:#303F9F;
}
.service > .content{
	position:relative;
	z-index:5;
}
.service > .content > h4{
	color:#202020;
	letter-spacing:2px;
	text-transform:uppercase;
	margin:25px 0;
}
.service > .content > p{
	line-height:1.6em;
	color:rgba(0,0,0,.4);
	margin:0;
}
.skills-block .section-header{
	margin-bottom:80px;
}
.skill{
	position:relative;
	margin-bottom:40px;
}
.skill:last-of-type{
	margin-bottom:0;
}
.skill > h4{
	margin-top:0;
	margin-bottom:5px;
	font-weight:500;
	letter-spacing:2px;
	text-transform:uppercase;
	font-size:14px;
}
.skill > .bar{
	background:#E0E0E0;
}
.skill > .bar > .percent{
	background:#303F9F;
	width:10%;
	height:6px;
}
.menu-btn{
	font-size:0;
	display:block;
	position:absolute;
	top:20px;
	right:20px;
	padding:25px;
	z-index:499;
}
.menu-btn > .lines{
	display:block;
	width:36px;
}
.menu-btn > .lines > span{
	display:block;
	width:100%;
	height:3px;
	background:#FFF;
	-webkit-transition:background 2.5s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:background 2.5s cubic-bezier(.785,.135,.15,.86);
	transition:background 2.5s cubic-bezier(.785,.135,.15,.86);
}

.pricing-block .section-header{
	margin-bottom:80px;
}
.p-table{
	padding:40px;
	text-align:center;
	position:relative;
}
.p-table:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:2;
	border:solid 1px rgba(0,0,0,.1);
}
.p-table:after{
	content: ' ';
	position:absolute;
	left:0;
	right:0;
	top:-10px;
	bottom:-10px;
	background:#303F9F;
	-webkit-transform:rotate(-4deg);
	-moz-transform:rotate(-4deg);
	-ms-transform:rotate(-4deg);
	-o-transform:rotate(-4deg);
	transform:rotate(-4deg);
	opacity:0;
	visibility:hidden;
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.p-table:hover:after{
	opacity:.4;
	visibility:visible;
}
.p-table > *{
	position:relative;
	z-index:5;
}

.p-table > .items{
	list-style:none;
	margin:0;
	padding:0;
	margin-bottom:35px;
	color:rgba(0,0,0,.4);
}
.p-table > .items > li{
	padding:10px 0;
	color:rgba(0,0,0,.4);
}
.p-table > .header{
	margin-bottom:35px;
}
.p-table > .header > h4{
	margin:0;
	font-size:32px;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom:45px;
}
.p-table > .header > .price{
	font-size:0;
}
.p-table > .header > .price > *{
	display:inline-block;
	*display:inline;
	*zoom:1;
	position:relative;
}
.p-table > .header > .price > .currency{
	font-size:16px;
	vertical-align:top;
	top:2em;
}
.p-table > .header > .price > .amount{
	font-size:94px;
	font-weight:900;
}
.p-table > .header > .price > .period{
	font-size:14px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:rgba(0,0,0,.4);
}



/*=======================================================
	Resume Section
=======================================================*/
.timeline-block .section-header{
	margin-bottom:80px;
}
.timeline{
	margin:0;
	padding:0;
	list-style:none;
	position:relative;
	padding:40px 0;
}
.timeline:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:1px;
	background:#D0D0D0;
}
.timeline > li{
	padding-left:40px;
	margin-bottom:40px;
}
.timeline > li:last-of-type{
	margin-bottom:0;
}
.timeline > li > .timeline-content{
	padding:25px;
	border:solid 1px #B6B6B6;
	position:relative;
}
.timeline > li > .timeline-content:before{
	content: ' ';
	position:absolute;
	top:15px;
	left:-40px;
	width:40px;
	height:1px;
	background:#B6B6B6;
}
.timeline > li > .timeline-content:after{
	content: ' ';
	position:absolute;
	height:16px;
	width:16px;
	border-radius:50%;
	background:#F0F0F0;
	border:solid 1px #B6B6B6;
	top:7px;
	left:-48px;
}
.timeline > li > .timeline-content > h4{
	margin:0;
	margin-bottom:5px;
}
.timeline > li > .timeline-content > em{
	margin-bottom:10px;
	display:block;
	color:rgba(0,0,0,.6);
}
.timeline > li > .timeline-content > em > span{
	margin-right:10px;
}
.timeline > li > .timeline-content > p{
	margin:0;
	line-height:1.6em;
	color:rgba(0,0,0,.4);
}
.testimonials-block .section-header{
	margin-bottom:80px;
}
.testimonial{
	position:relative;
}
.testimonial > .icon{
	font-size:64px;
	color:#303F9F;
	opacity:.6;
}
.testimonial > p{
	font-size:18px;
	line-height:1.8em;
	color:rgba(0,0,0,.6);
	font-style:italic;
	margin-top:10px;
}
.testimonial > .author{
	margin-top:25px;
	margin-bottom: 2rem;
}
.testimonial > .author > h4{
	margin:0;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom:5px;
}
.testimonial > .author > span{
	font-style:italic;
	color:rgba(0,0,0,.6);
}
.testimonials-slider .owl-controls{
	font-size:0;
	margin-top:60px;
}
.testimonials-slider .owl-controls .owl-dot{
	display:inline-block;
	*display:inline;
	*zoom:1;
	padding:15px 0;
	margin:-15px 0;
	margin-right:3px;
}
.testimonials-slider .owl-controls .owl-dot > span{
	display:block;
	height:6px;
	width:36px;
	margin-right:7px;
	background:#303F9F;
	opacity:.4;
}
.testimonials-slider .owl-controls .owl-dot.active > span{
	opacity:1;
}
.img-fluid {
	max-width: 100%;
	height: auto;
  }
  .rounded-circle {
	border-radius: 50% !important;
  }



/*=======================================================
	Portfolio Section
=======================================================*/

#portfolio {
	background: #ebeeee;
	padding-top: 90px;
	padding-bottom: 60px;
  }
  #portfolio h1 {
	font: 34px "opensans-semibold", sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: center;
	margin-bottom: 48px;
	color: #95a3a3;
  }
  
  /* Portfolio Content */
  #portfolio-wrapper .columns {
	margin-bottom: 36px;
  }
  .portfolio-item .item-wrap {
	background: #fff;
	overflow: hidden;
	position: relative;
  
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  }
  .portfolio-item .item-wrap a {
	display: block;
	cursor: pointer;
  }
  
  /* overlay */
  .portfolio-item .item-wrap .overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0);
  
	-webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
  
	// background: url(../img/overlay-bg.png) repeat;
  }
  .portfolio-item .item-wrap .link-icon {
	display: block;
	color: var(--theme-font-color-primary);
	height: 30px;
	width: 30px;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
  
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0);
  
	-webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
  
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15px;
	margin-top: -15px;
  }
  .portfolio-item .item-wrap img {
	vertical-align: bottom;
  }
  .portfolio-item .portfolio-item-meta {
	padding: 18px;
  }
  .portfolio-item .portfolio-item-meta h5 {
	font: 14px/21px "opensans-bold", sans-serif;
	color: var(--theme-font-color-primary);
  }
  .portfolio-item .portfolio-item-meta p {
	font: 12px/18px "opensans-light", sans-serif;
	color: #c6c7c7;
	margin-bottom: 0;
  }
  
  /* on hover */
  .portfolio-item:hover .overlay {
	opacity: 1;
	-moz-opacity: 1;
	filter: alpha(opacity=100);
  }
  .portfolio-item:hover .link-icon {
	opacity: 1;
	-moz-opacity: 1;
	filter: alpha(opacity=100);
  }
  
  /* popup modal */
  .popup-modal {
	/* max-width: 550px; */
	max-width: 100%;
	background: var(--theme-font-color-primary);
	color: var(--theme-font-color-secondary);
	position: relative;
	margin: 25px;
	padding-top: 25px;
	border-radius: 5px;
  }

  .popup-modal .skills-box{
	padding: 0px 36px 0px 36px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	height: 1.15em;
	align-items: flex-end;
	justify-content: flex-end;
  }

  .popup-modal .description-box {
	padding: 12px 36px 18px 36px;
  }
  .popup-modal .description-box h4 {
	font: 15px/24px "opensans-bold", sans-serif;
	margin-bottom: 12px;
	color: var(--theme-quartnary-clr);
  }
  .popup-modal .description-box p {
	font: 14px/24px "opensans-regular", sans-serif;
	color: #a1a1a1;
	margin-bottom: 12px;
  }
  .popup-modal .description-box .categories {
	font: 11px/21px "opensans-light", sans-serif;
	color: #a1a1a1;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: block;
	text-align: left;
  }
  .popup-modal .description-box .categories i {
	margin-right: 8px;
  }
  .popup-modal .link-box {
	padding: 18px 36px;
	background: #303F9F;
	text-align: right;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
  }
  .popup-modal .link-box a {
	text-align: right;
	color: var(--theme-font-color-primary);
	font: 11px/21px "opensans-bold", sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	cursor: pointer;
  }
  .popup-modal a:hover {
	color: var( --theme-primary-clr);
  }
  .popup-modal a.popup-modal-dismiss {
	margin-left: 24px;
  }
  
  /* fadein/fadeout effect for modal popup
  /* ------------------------------------------------------------------ */
  
  /* content at start */
  .mfp-fade.mfp-wrap .mfp-content .popup-modal {
	opacity: 0;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
  }
  /* content fadein */
  .mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
	opacity: 1;
  }
  /* content fadeout */
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
	opacity: 0;
  }
  
.portfolio-block .section-header{
	margin-bottom:80px;
}
.portfolio-filters,
.portfolio-items{
	position:relative;
	list-style:none;
	font-size:0;
	margin:0;
	padding:0;
}
.portfolio-filters{
	margin-bottom:25px;
}
.portfolio-filters > li{
	display:inline-block;
	*display:inline;
	*zoom:1;
	margin-right:25px;
}
.portfolio-filters > li > a{
	display:block;
	font-size:16px;
	font-weight:500;
	color:rgba(0,0,0,.4);
	text-decoration:none;
	text-transform:uppercase;
	letter-spacing:4px;
	padding:15px 0;
	margin:-15px 0;
}
.portfolio-filters > li > a.active,
.portfolio-filters > li > a:hover{
	color:#202020;
}
.portfolio-items{
	margin:-5px;
	overflow:visible !important;
}
.portfolio-items > li{
	width:33.3333%;
}
.portfolio-items > li > .inner{
	margin:5px;
	position:relative;
}
.portfolio-items > li{
	z-index:2;
}
.portfolio-items > li:hover{
	z-index:9;
}
.portfolio-items > li > .inner > .overlay:before{
	content: ' ';
	position:absolute;
	top:0;
	bottom:0;
	right:-10px;
	left:-10px;
	background:#303F9F;
	opacity:.9;
	-webkit-transform:rotate(0deg) scale(0);
	-moz-transform:rotate(0deg) scale(0);
	-ms-transform:rotate(0deg) scale(0);
	-o-transform:rotate(0deg) scale(0);
	transform:rotate(0deg) scale(0);
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.portfolio-items > li > .inner:hover > .overlay:before{
	-webkit-transform:rotate(-10deg) scale(1);
	-moz-transform:rotate(-10deg) scale(1);
	-ms-transform:rotate(-10deg) scale(1);
	-o-transform:rotate(-10deg) scale(1);
	transform:rotate(-10deg) scale(1);
}
.portfolio-items > li > .inner:hover > .overlay{
	opacity:1;
}
.portfolio-items > li > .inner > .overlay{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:10;
	text-align:center;
	opacity:0;
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.portfolio-items > li > .inner > .overlay > .view-project{
	font-size:18px;
	line-height:18px;
	vertical-align:middle;
	position:absolute;
	top:50%;
	margin-top:-29px;
	color:#FFF;
	color:rgba(255,255,255,.6);
	text-decoration:none;
	left:0;
	right:0;
	padding:20px 0;
	text-transform:uppercase;
	letter-spacing:2px;
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.portfolio-items > li > .inner > .overlay > .view-project:hover{
	color:#FFF;
}
/*== Project Popup ==*/
.mfp-bg{
	background:#303F9F;
}
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out; 
	-moz-transition: all 0.2s ease-in-out; 
	-o-transition: all 0.2s ease-in-out; 
	transition: all 0.2s ease-in-out; 
	-webkit-transform: scale(0.8); 
	-moz-transform: scale(0.8); 
	-ms-transform: scale(0.8); 
	-o-transform: scale(0.8); 
	transform: scale(0.8); 
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1); 
	-moz-transform: scale(1); 
	-ms-transform: scale(1); 
	-o-transform: scale(1); 
	transform: scale(1); 
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8); 
	-moz-transform: scale(0.8); 
	-ms-transform: scale(0.8); 
	-o-transform: scale(0.8); 
	transform: scale(0.8); 
	opacity: 0;
}
.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out; 
	-moz-transition: opacity 0.3s ease-out; 
	-o-transition: opacity 0.3s ease-out; 
	transition: opacity 0.3s ease-out;
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: .96;
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}
.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
}
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
}
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;
	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
}
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out; 
	-moz-transition: opacity 0.3s ease-out; 
	-o-transition: opacity 0.3s ease-out; 
	transition: opacity 0.3s ease-out;
}
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}
.mfp-container{
	padding:28px !important;
	overflow:auto;
}
.popup-box{
	background:#FFF;
	max-width:450px;
	margin:0 auto;
	position:relative;
	-webkit-box-shadow: 0 0 0 4px #FFF;
	-moz-box-shadow: 0 0 0 4px #FFF;
	box-shadow: 0 0 0 4px #FFF;
}
.popup-box > .mfp-close{
	top:2px;
	right:2px;
	background:#FFF;
	height:48px;
	width:48px;
	opacity:1;
	font-size:26px;
}
.popup-box > .mfp-close:hover{
	background:#FAFAFA;
}
.popup-box > .content{
	padding:20px;
}
.popup-box > .content > h4{
	text-transform:uppercase;
	letter-spacing:2px;
	font-weight:bold;
	font-size:20px;
}
.popup-box > .content > p{
	font-size:14px;
	color:rgba(0,0,0,.45);
	font-weight:500;
}


/*=======================================================
	Contact Section
=======================================================*/
.contact-block .section-header{
	margin-bottom:80px;
}
.contact-form > .form-group:last-of-type{
	margin:0;
}
.alert{
	border-radius:0;
	padding:17px;
	border:solid 1px;
	color:#FFF;
	background:transparent;
}
.alert.alert-success{
	border-color:#66bb6a; 
	color:#66bb6a; 
}
.alert.alert-danger{
	border-color:#ef5350;
	color:#ef5350;
}
.form-group{
	margin-bottom:20px;
}
.form-control{
	padding:14px;
	height:auto;
	border-radius:0;
	-webkit-box-shadow:none !important;
	-moz-box-shadow:none !important;
	box-shadow:none !important;
	border:solid 1px rgba(0,0,0,.1);
	background:transparent;
	-webkit-transition:none;
	-moz-transition:none;
	transition:none;
}
.form-control:focus{
	border-color:#303F9F;
}
.contact-form .btn-custom{
	padding:19px 35px;
}
.contact-info{
	margin-bottom:25px;
}
.contact-info:before,
.contact-info:after{
	content: ' ';
	display:table;
}
.contact-info:after{
	clear:both;
}
.contact-info > i{
	width:60px;
	display:block;
	float:left;
	font-size:52px;
	color:#303F9F;
}
.contact-info > p{
	float:left;
	font-size:16px;
	line-height:1.6em;
	font-style:italic;
	color:rgba(0,0,0,.4);
	margin:0;
	margin-top:5px;
}
@media(min-width:992px){
	.contact-info-icons{
		padding-left:30px;
	}
}



/*=======================================================
	Preloader
=======================================================*/
#preloader{
	position:fixed;
	top:0;
	left:0;
	height:100%;
	width:100%;
	z-index:999;
	-webkit-transition: all .3s 1s ease;
	-moz-transition: all .3s 1s ease;
	transition: all .3s 1s ease;
}
#preloader > .left,
#preloader > .right{
	position:absolute;
	top:0;
	width:50%;
	height:100%;
	background:#FFF;
	overflow:hidden;
	z-index:99;
	-webkit-transition:all .7s .3s ease;
	-moz-transition:all .7s .3s ease;
	transition:all .7s .3s ease;
}
#preloader > .left{
	left:0;
}
#preloader > .right{
	right:0;
}

.loader{
	height:140px;
	width:140px;
	border:solid 4px #FFF;
	border-top-color:#303F9F;
	border-right-color:#303F9F;
	border-radius:50%;
	position:absolute;
	top:50%;
	right:0;
	left:0;
	margin:auto;
	margin-top:-70px;
	transition:all .3s ease;
	z-index:199;
	-webkit-animation:loader 2s infinite linear;
	-moz-animation:loader 2s infinite linear;
	animation:loader 2s infinite linear;
}
.loader:before,
.loader:after{
	content: ' ';
	position:absolute;
	border:inherit;
	border-radius:50%;
}
.loader:before{
	left:15px;
	top:15px;
	bottom:15px;
	right:15px;
	opacity:.5;
	-webkit-animation: loader 3s infinite linear;
	-moz-animation: loader 3s infinite linear;
	animation: loader 3s infinite linear;
}
.loader:after{
	left:30px;
	right:30px;
	top:30px;
	bottom:30px;
	opacity:.25;
	-webkit-animation: loader 1.5s infinite linear;
	-moz-animation: loader 1.5s infinite linear;
	animation: loader 1.5s infinite linear;
}
@-webkit-keyframes loader{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}
@-moz-keyframes loader{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}
@keyframes loader{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}



/*=======================================================
	Responsive CSS
=======================================================*/
@media(max-width:992px){
	.info-list{
		margin-bottom:25px;
	}
	.p-table,
	.service{
		margin-bottom:25px;
	}
	.skills-block .skill{
		margin-bottom:40px;
	}
	div:last-of-type > .p-table,
	div:last-of-type > .skill:last-of-type,
	div:last-of-type > .servic{
		margin-bottom:0;
	}
	.portfolio-items > li{
		width:50%;
	}
	.section{
		padding-top:90px;
	}
	.section-block{
		padding:90px;
		padding-top:0;
	}
	.contact-info-icons{
		margin-top:60px;
	}
}
@media(max-width:768px){
	.portfolio-items > li{
		width:100%;
	}
	.main-content{
		// top:0;
		left:0;
		right:0;
		bottom:0;
	}
	.about-btns > .btn-custom{
		margin-bottom:10px;
	}
	.intro-text > .intro-btns > .btn-custom{
		margin-bottom:10px;
	}
	.intro-text > h1{
		font-size:47px;
	}
}
@media(max-width:550px){
	.section{
		padding-top:70px;
	}
	.section-block{
		padding:70px;
		padding-top:0;
	}
}
@media(max-width:400px){
	.section{
		padding-top:40px;
	}
	.section-block{
		padding:40px;
		padding-top:0;
	}
}
}

.footer-text{
	text-align: center;
}