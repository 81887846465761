.blogbody{
    position: relative;
	background-color: var(--primary-clr);
	padding: 0 var(--padding);
	display: grid;
	grid-template-columns: repeat(1fr, 10);
}

.title{
    position: relative;
    text-align: center;
    font-family: var(--primary-font);
    color: var(--font-color-primary);
    margin: 4em 0;
    display: flex;
    gap: 1em;
    font-weight: 700;
}

.title > h1{
    width: 100%;
    font-size: 4rem;
}

.heroimg{
    position: absolute;
	right: 0;
	bottom: 0;
	height: 75vmax;
	transform: translate(50%, 100%);
	z-index: 0;
}

.blogs{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
    margin-bottom: 5rem;
}

@media screen and (max-width: 1200px) {
    .blogs{
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .blogs{
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 3rem;
    }

    .actions, .title{
        flex-direction: column;
        gap: 1em;
    }

    .actions > a{
        width: 100%;
    }

    .finish{
        height: 4rem;
        width: 100%;
    }

}

.blog{
    font-family: var(--secondary-font);
    position: relative;
    height: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    gap: 1em;
    text-decoration: none;
    transition: all 0.25s ease;
    padding: 14px;
}

.blogImg{
    width: 100%;
    height: 15rem;
    object-fit: cover;
    margin: 0;
}

.blogTitle{
    margin: 0;
    text-align: center;
    color: var(--font-color-primary);
    font-family: var(--secondary-font);
}

.blogContentWrapped{
    font-size: 0.8rem;
    font-family: var(--primary-font);
    padding: 0 0.5em;
    word-break: break-word;
    color: var(--font-color-primary);
    text-align: justify;
}

.blog:hover{
    background-color: rgba(255, 255, 255, 0.50);
    box-shadow: 0.15rem 0.15rem 1rem 0 rgba(0, 0, 0, 0.01);
}


.previewButton{
    margin: 0;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--font-color-primary);
    margin: auto;
    text-decoration: none;
}

.paginateContainer {
    display: flex;
    margin: auto;
    text-decoration: none;
    list-style: none;
    font-family: var(--primary-font);
}

.pages {
    text-align: center;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 0px 10px;
    cursor: pointer;
}

.acitvePage {
    background-color: black;
    color: white;
}

.activePageLink {
    color: white;
}

.disabled{
    pointer-events: none;
    display: none;
    background-color: black;
}

a{
	cursor: pointer;
	  text-decoration: none;
	  color: var(--font-color-primary);
}

.loadingBlog, .noBlogs{
    position: relative;
    grid-column: 1/-1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 30vh;
    font-family: var(--secondary-font);
    font-weight: bold;
    font-size: 2rem;
}