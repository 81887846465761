/*=============================================================
    Authour URI: www.binarytheme.com
    License: Commons Attribution 3.0

    http://creativecommons.org/licenses/by/3.0/

    100% Free To use For Personal And Commercial Use.
    IN EXCHANGE JUST GIVE US CREDITs AND TELL YOUR FRIENDS ABOUT US
   
    ========================================================  */

/* =============================================================
   GENERAL STYLES
 ============================================================ */
 #theme4{
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  background-color: #F4F4F4;
}
#theme4{
.pad-top {
  padding-top: 50px;
}
.pad-top-bottom {
  padding-top: 20px;
  padding-bottom: 30px;
}
.text-center {
  text-align: center;
}

section {
  padding: 40px 0px 40px 0px;
}

.section-block{
	padding:110px;
	padding-top:0;
}

.pricing-block .section-header{
	margin-bottom:80px;
}
.section-header{
	position:relative;
  text-align: center;
}
.section-header > h2{
	font-size:28px;
	font-weight:200;
	margin:0;
	text-transform:uppercase;
	letter-spacing:4px;
}

.color{
	color:rgb(255, 15, 101);
}

.p-table{
	padding:40px;
	text-align:center;
	position:relative;
}
.p-table:before{
	content: ' ';
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:2;
	border:solid 1px rgba(0,0,0,.1);
}
.p-table:after{
	content: ' ';
	position:absolute;
	left:0;
	right:0;
	top:-10px;
	bottom:-10px;
	background:#303F9F;
	-webkit-transform:rotate(-4deg);
	-moz-transform:rotate(-4deg);
	-ms-transform:rotate(-4deg);
	-o-transform:rotate(-4deg);
	transform:rotate(-4deg);
	opacity:0;
	visibility:hidden;
	-webkit-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	-moz-transition:all .3s cubic-bezier(.785,.135,.15,.86);
	transition:all .3s cubic-bezier(.785,.135,.15,.86);
}
.p-table:hover:after{
	opacity:.4;
	visibility:visible;
}
.p-table > *{
	position:relative;
	z-index:5;
}

.p-table > .items{
	list-style:none;
	margin:0;
	padding:0;
	margin-bottom:35px;
	color:rgba(0,0,0,.4);
}
.p-table > .items > li{
	padding:10px 0;
	color:rgba(0,0,0,.4);
}
.p-table > .header{
	margin-bottom:35px;
}
.p-table > .header > h4{
	margin:0;
	font-size:32px;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom:45px;
}
.p-table > .header > .price{
	font-size:0;
}
.p-table > .header > .price > *{
	display:inline-block;
	*display:inline;
	*zoom:1;
	position:relative;
}
.p-table > .header > .price > .currency{
	font-size:16px;
	vertical-align:top;
	top:2em;
}
.p-table > .header > .price > .amount{
	font-size:94px;
	font-weight:900;
}
.p-table > .header > .price > .period{
	font-size:14px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:rgba(0,0,0,.4);
}

p {
  line-height: 30px;
  font-size: 16px;
  padding: 10px 0px 10px 0px;
  word-wrap: break-word;
}
h1 {
  font-weight: 900;
  color: rgb(255, 15, 101);
}
.color-strip {
  margin: 20px 0px 30px 0px;
  max-width: 230px;
  height: 2px;
  border-top: 0;
  background: #c4e17f;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(
    left,
    #c4e17f,
    #c4e17f 12.5%,
    #f7fdca 12.5%,
    #f7fdca 25%,
    #fecf71 25%,
    #fecf71 37.5%,
    #f0776c 37.5%,
    #f0776c 50%,
    #db9dbe 50%,
    #db9dbe 62.5%,
    #c49cde 62.5%,
    #c49cde 75%,
    #669ae1 75%,
    #669ae1 87.5%,
    #62c2e4 87.5%,
    #62c2e4
  );
  background-image: -moz-linear-gradient(
    left,
    #c4e17f,
    #c4e17f 12.5%,
    #f7fdca 12.5%,
    #f7fdca 25%,
    #fecf71 25%,
    #fecf71 37.5%,
    #f0776c 37.5%,
    #f0776c 50%,
    #db9dbe 50%,
    #db9dbe 62.5%,
    #c49cde 62.5%,
    #c49cde 75%,
    #669ae1 75%,
    #669ae1 87.5%,
    #62c2e4 87.5%,
    #62c2e4
  );
  background-image: -o-linear-gradient(
    left,
    #c4e17f,
    #c4e17f 12.5%,
    #f7fdca 12.5%,
    #f7fdca 25%,
    #fecf71 25%,
    #fecf71 37.5%,
    #f0776c 37.5%,
    #f0776c 50%,
    #db9dbe 50%,
    #db9dbe 62.5%,
    #c49cde 62.5%,
    #c49cde 75%,
    #669ae1 75%,
    #669ae1 87.5%,
    #62c2e4 87.5%,
    #62c2e4
  );
  background-image: linear-gradient(
    to right,
    #c4e17f,
    #c4e17f 12.5%,
    #f7fdca 12.5%,
    #f7fdca 25%,
    #fecf71 25%,
    #fecf71 37.5%,
    #f0776c 37.5%,
    #f0776c 50%,
    #db9dbe 50%,
    #db9dbe 62.5%,
    #c49cde 62.5%,
    #c49cde 75%,
    #669ae1 75%,
    #669ae1 87.5%,
    #62c2e4 87.5%,
    #62c2e4
  );
}

/* =============================================================
  BUTTON SECTION STYLES
 ============================================================ */
.custom-btn-one {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgb(255, 15, 101);
}
.custom-btn-one:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.btn-style-two {
  color: #fff;
  background-color: #797979;
  border-color: #929292;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}
.btn-style-two:hover {
  color: #fff;
}

.btn-style-three {
  color: #fff;
  background-color: #3bad72;
  border-color: #44ebb5;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}
.btn-style-three:hover {
  color: #fff;
}
/* =============================================================
  HOME SECTION STYLES
 ============================================================ */
.overlay{
  position: relative;
}
#home-sec {
  background: url(../img/home.jpg) no-repeat center center;
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
#home-sec .overlay {
  padding-top: 100px;
  background: rgba(0, 0, 0, 0.8);
  min-height: 790px;
}
#home-sec .main-img {
  max-height: 200px;
  border: 5px solid rgb(255, 15, 101);
  content: url("../img/sad.jpg"); /*SAD IMAGE URL  - LEFT SIDE IMAGE*/
  cursor: pointer;
}
#home-sec .main-img:hover {
  content: url("../img/smile.jpg"); /*SIMLE IMAGE URL ON HOVER - LEFT SIDE IMAGE*/
  cursor: pointer;
}
#home-sec a,
#home a:hover {
  text-decoration: none;
}
#home-sec .main-img-2 {
  max-height: 200px;
  border: 5px solid rgb(255, 15, 101);
  content: url("../img/smile.jpg"); /*SMILE IMAGE URL  - RIGHT SIDE IMAGE*/
  cursor: pointer;
}
#home-sec .main-img-2:hover {
  content: url("../img/sad.jpg"); /*SAD IMAGE URL ON HOVER - RIGHT SIDE IMAGE*/
  cursor: pointer;
}

#home-sec h1 {
  font-weight: 900;
  padding: 20px 0px 0px 0px;
}
#home-sec h2 {
  font-weight: 400;
  padding: 0px 0px 30px 0px;
  color: #fff;
}
#home-sec h3 {
  padding: 5px 0px;
  color: #fff;
  font-weight: 800;
}
#home-sec small {
  color: #fff;
}
.icon-round {
  font-size: 30px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  cursor: pointer;
}
.icon-round:hover {
  background-color: rgb(255, 15, 101);
}

.icon-round-active {
  background-color: rgb(255, 15, 101);
}

/* =============================================================
  ABOUT SECTION STYLES
 ============================================================ */

#about-sec .social-icon {
  padding: 10px 0px 30px 0px;
}
#about-sec .social-icon a > img {
  padding: 5px;
}

/* =============================================================
  SKILL SECTION STYLES ( easy-pie-chart css)
 ============================================================ */
#skill-sec h4 {
  font-weight: 600;
  color: rgb(255, 15, 101);
}
#skill-sec {
  /* background: url(../img/skill.jpg) no-repeat center center; */
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  color: #fff;
  background-attachment: fixed;
}
#skill-sec .overlay {
  padding-top: 50px;
  /* background:url(../img/overlays/15.png); */
  // min-height: 450px;
}
.chart {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.percent {
  display: inline-block;
  line-height: 150px;
  z-index: 2;
  font-size: 2em;
}
.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 1em;
}
.skills{
  position: relative;
  top:25px;
}
/* =============================================================
  DOWNLOAD SECTION STYLES
 ============================================================ */
.board {
  width: 85%;
  margin: 0px auto;
}
.board .nav-tabs {
  position: relative;
  margin: 40px auto;
  margin-bottom: 0;
  box-sizing: border-box;
}

p.narrow {
  margin: 10px auto;
  text-transform: uppercase;
}

.liner {
  height: 2px;
  background: #ddd;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}
.board .tab-content span {
  display: block;
}
.board .tab-content i {
  color: rgb(255, 15, 101);
  margin-right: 15px;
  margin-left: 15px;
  font-style: normal;
}
span.round-tabs {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: white;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;
}

span.round-tabs.one {
  color: rgb(34, 194, 34);
  border: 2px solid rgb(34, 194, 34);
}

.board li.active span.round-tabs.one {
  background: #fff !important;
  border: 2px solid #ddd;
  color: rgb(34, 194, 34);
}

.board span.round-tabs.two {
  color: #febe29;
  border: 2px solid #febe29;
}

.board li.active span.round-tabs.two {
  background: #fff !important;
  border: 2px solid #ddd;
  color: #febe29;
}

span.round-tabs.three {
  color: #3e5e9a;
  border: 2px solid #3e5e9a;
}

.board li.active span.round-tabs.three {
  background: #fff !important;
  border: 2px solid #ddd;
  color: #3e5e9a;
}

span.round-tabs.four {
  color: #f1685e;
  border: 2px solid #f1685e;
}

.board li.active span.round-tabs.four {
  background: #fff !important;
  border: 2px solid #ddd;
  color: #f1685e;
}

span.round-tabs.five {
  color: #999;
  border: 2px solid #999;
}

.board li.active span.round-tabs.five {
  background: #fff !important;
  border: 2px solid #ddd;
  color: #999;
}

.nav-tabs > li.active > a span.round-tabs {
  background: #fafafa;
}
.nav-tabs > li {
  width: 20%;
}

.board li:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: rgb(255, 15, 101) !important;
  transition: 0.1s ease-in-out;
}
.board li.active:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: #ddd;
}
.nav-tabs > li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.nav-tabs > li a:hover {
  background: transparent;
}

.tab-content {
}
.tab-pane {
  position: relative;
  padding-top: 25px;
}
.tab-content .head {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  padding-bottom: 3px;
}
.btn-outline-rounded {
  padding: 10px 40px;
  margin: 20px 0;
  border: 2px solid transparent;
  border-radius: 25px;
}

.btn.green {
  background-color: #5cb85c;
  color: #ffffff;
}

@media (max-width: 585px) {
  .board {
    width: 90%;
    height: auto !important;
  }
  span.round-tabs {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .tab-content .head {
    font-size: 20px;
  }
  .nav-tabs > li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .board li.active:after {
    content: " ";
    position: absolute;
    left: 35%;
  }

  .btn-outline-rounded {
    padding: 12px 20px;
  }
}

#lang-sec {
  /* background: url(../img/skill.jpg) no-repeat center center; */
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  /* color: #fc1818; */
  background-attachment: fixed;
}
#lang-sec h4 {
  font-weight: 600;
  color: rgb(255, 15, 101);
}
#lang-sec .overlay {
  padding-top: 50px;
  /* background:url(../img/overlays/15.png); */
  // min-height: 450px;
}
/* Pricing card */
.pricing-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.pricing-wrapper .pricing-card {
  display: block;
  border: 1px solid #dee2e6;
  padding: 30px 15px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  background: #fff;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .pricing-wrapper .pricing-card {
    -webkit-transition: none;
    transition: none;
  }
}

.pricing-wrapper .pricing-card-header .pricing-card-icon {
  width: 70px;
  margin-bottom: 25px;
}

.pricing-wrapper .pricing-card-footer {
  margin: 15px 0;
}

.pricing-wrapper .pricing-card-footer span {
  font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1300 - 300)));
  font-weight: 600;
  opacity: 0.8;
}

.pricing-wrapper .pricing-card-footer span:first-child {
  position: relative;
  right: -6px;
  top: -6px;
  font-size: calc(15px + (25 - 15) * ((100vw - 300px) / (1300 - 300)));
}

.pricing-wrapper .pricing-card-title {
  margin-bottom: 15px;
  font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1300 - 300)));
}

.pricing-wrapper .pricing-card-list p {
  margin-bottom: 12px;
  font-weight: 500;
  opacity: 0.6;
}

.pricing-wrapper .pricing-card-list p i {
  color: #ec185d;
}

.pricing-wrapper .pricing-card:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  border-radius: 0 !important;
}

.pricing-wrapper .pricing-card:first-child {
  border-radius: 0 0 0 0;
}

.pricing-wrapper .pricing-card:nth-child(2) {
  margin-left: -1px;
  margin-right: -1px;
}

.pricing-wrapper .pricing-card:last-child {
  border-radius: 0 0 0 0;
}

@media (max-width: 767.98px) {
  .pricing-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .pricing-card {
    border-radius: 0 !important;
    margin: 0 0 15px 0 !important;
    width: 100%;
  }
}

/*=====================================
  TESTIMONIALS STYLES 
    ===================================*/

#testimonial-main {
  background: url(../img/testi.jpg) no-repeat center center;
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
#testimonial-main .overlay {
  padding-top: 60px;
  background: rgba(0, 0, 0, 0.8);
  min-height: 400px;
}
#testimonial-main h4 {
  line-height: 40px;
}
#testimonial-main i {
  padding: 5px;
}
#testimonial-main img {
  border: 3px solid rgb(255, 15, 101);
  margin: 5px;
}
/*=====================================
  PORTFOLIO STYLES 
    ===================================*/

#portfolio-sec {
  padding-bottom: 100px;
  padding-top: 50px;
}
#portfolio-sec i {
  margin-right: 5px;
  color: #fff;
  font-size: 20px;
}
#portfolio-sec .col-xs-12.col-sm-4.col-md-3 {
  padding: 0;
}

.portfolio-sec-wrap {
  position: relative;
  cursor: pointer;
}

#portfolio-sec a:hover {
  text-decoration: none;
}

.portfolio-sec-wrap img {
  width: 100%;
}

.portfolio-sec-wrap .portfolio-sec-inner {
  top: 0;
  background: transparent;
  opacity: 0.8;
  width: 100%;
  border-radius: 0;
  margin-bottom: 0;
  word-wrap: break-word;
}

.portfolio-sec-wrap .portfolio-sec-inner h3 {
  margin: 10px 0;
}

.portfolio-sec-wrap .portfolio-sec-inner h3 a {
  font-size: 24px;
  color: #fff;
}

.portfolio-sec-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0;
  background-color: rgb(255, 15, 101);
  color: #fff;
  vertical-align: middle;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;
  padding: 30px;
}

.portfolio-sec-wrap .overlay .preview {
  bottom: 0;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  border-radius: 0;
  background: transparent;
  text-align: center;
  color: #fff;
}

.portfolio-sec-wrap:hover .overlay {
  opacity: 1;
}

/*=====================================
  HIRE STYLES 
    ===================================*/
#hire-sec {
  background: url(../img/hire.jpg) no-repeat center center;
  padding: 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
#hire-sec .overlay {
  background: rgba(0, 0, 0, 0.8);
  min-height: 570px;
}

/*=====================================
  FOOTER STYLES 
    ===================================*/
#footer-sec h3 {
  color: rgb(255, 15, 101);
}
#footer-sec i {
  color: rgb(255, 15, 101);
  margin-right: 10px;
}
/*=====================================
  SCROLLUP STYLES 
    ===================================*/
a.scrollup {
  background: rgb(255, 15, 101);
  height: 32px;
  width: 32px;
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  opacity: 0.7;
}

#testimonial-main{
  ul{
    padding-left: 0;
  }
}
#portfolio-sec{
  .services-inner-box {
    padding: 15px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 50px 50px 0 50px;
    position: relative;
    transition: all 0.3s ease-in-out 0s;
}
.services-inner-box::after {
  background: rgb(255, 15, 101);
  width: 0px;
  height: 0px;
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50px 50px 0px 50px;
  transition: all 0.3s ease-in-out 0s;
  z-index: 0;
}
.services-inner-box h2 {
  font-size: 22px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.services-inner-box p {
  font-size: 14px;
  position: relative;
  z-index: 1;
}
.services-inner-box a {
  color:rgb(255, 15, 101);
}

}
 }